/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

import { OutboundLink } from "gatsby-plugin-google-analytics"

const Bio = () => {
  return (
    <div id="about">
      <p>Hi, I'm <span className="name">Ash Ngu</span>.</p><p> I'm a designer and developer with a focus on data visualization. I'm energized by efforts to help people understand and care for the world.</p>

      <p>Currently, I build data visualizations, stories, and tools for <OutboundLink className="quietLink" href="https://www.propublica.org/people/ash-ngu" target="_blank" rel="noreferrer">ProPublica</OutboundLink>. Previously, I made graphics at <OutboundLink className="quietLink" href="https://www.nytimes.com/by/ash-ngu" target="_blank" rel="noreferrer">The New York Times</OutboundLink>.</p>

      <p>Sometimes I take <OutboundLink href="#photography" className="quietLink">photos</OutboundLink> and make functional objects from <OutboundLink href="#woodworking" className="quietLink"> wood</OutboundLink>.</p>

      <div id="contact">
        <OutboundLink href="mailto:ash.ngu@gmail.com">ashngu at gmail</OutboundLink> •
        <OutboundLink href="https://www.instagram.com/ashnguuu/" target="_blank" rel="noreferrer"> Instagram</OutboundLink> •
        <OutboundLink href="https://www.linkedin.com/m/in/ashngu" target="_blank" rel="noreferrer"> LinkedIn</OutboundLink> ✨
      </div>
    </div >
  )
}
export default Bio
